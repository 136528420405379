import React, { useState, useEffect } from "react";
import UserForm from "./UserForm";
import UserTable from "./UserTable";
import "./App.css"; // Import CSS file for styling
import axios from "axios";
import { API_URL } from "./config";

function App() {
  const [users, setUsers] = useState([]);
  // Function to fetch users from the API
  const fetchUsers = async () => {
    try {
      const response = await axios.get(`${API_URL}/api/users`);
      setUsers(response.data);
      console.log("call fetch users");
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  // Fetch users on component mount
  useEffect(() => {
    fetchUsers();
  }, []);

  return (
    <div className="App">
      <h1 className="App-title">My Pilot App</h1>
      <UserForm fetchUsers={fetchUsers} />
      <UserTable users={users} />
    </div>
  );
}

export default App;
