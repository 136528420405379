import React, { useState } from "react";
import axios from "axios";

import { API_URL } from "./config";
import "./UserForm.css"; // Import CSS file for styling

function UserForm({ fetchUsers }) {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [nickname, setNickname] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.post(`${API_URL}/api/users`, {
        firstName,
        lastName,
        nickname,
      });

      // Handle successful response (e.g., clear form, update table)
      console.log("User saved:", response.data);
      setFirstName(""); // Clear the form fields
      setLastName("");
      setNickname("");
      fetchUsers();
    } catch (error) {
      // Handle error
      console.error("Error saving user:", error);
    }
  };

  return (
    <div className="user-form-container">
      <form onSubmit={handleSubmit} className="user-form">
        <div>
          <label htmlFor="firstName">First Name:</label>
          <input
            type="text"
            id="firstName"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
        </div>
        <div>
          <label htmlFor="lastName">Last Name:</label>
          <input
            type="text"
            id="lastName"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
        </div>
        <div>
          <label htmlFor="nickname">Pilot Nickname:</label>
          <select
            id="nickname"
            value={nickname}
            onChange={(e) => setNickname(e.target.value)}
          >
            <option value="">Select Nickname</option>
            <option value="Maverick">Maverick</option>
            <option value="Iceman">Iceman</option>
            <option value="Goose">Goose</option>
            {/* Add more nickname options */}
          </select>
        </div>
        <button type="submit">Save</button>
      </form>
    </div>
  );
}

export default UserForm;
