import React from "react";
import "./UserTable.css"; // Import CSS file for styling

function UserTable({ users }) {
  return (
    <table className="user-table">
      <thead>
        <tr>
          <th>First Name</th>
          <th>Last Name</th>
          <th>Pilot Nickname</th>
        </tr>
      </thead>
      <tbody>
        {users.map((user) => (
          <tr key={user.id}>
            <td>{user.first_name}</td>
            <td>{user.last_name}</td>
            <td>{user.pilot_nickname}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default UserTable;
